.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    max-width: 740px;
    min-width: 320px;
    width: 100%;
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .popupLogo{
    position: absolute;
    top: 20px;
    left: 20px;    
  }
  
  .slide {
    display: none;
  }
  
  .slide.active {
    display: block;
  }  

  
  .slideElement{
    min-width: 320px;
    max-width: 520px;
    width: 100%;
  }
  
  .slideElement > *{
    width: 100%;
  }
  
  .formFlex{
    width: 100%;
    display: flex;
  }
  
  .fullS{
    max-width: 740px;
    min-width: 320px;
    width: 100%;
  }

  .formSl{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
  }
  
  input {
    flex: 1;
    padding: 6px 12px;
    border: none;
    background: black;  
    color: white;
    font-size: 14px;
    line-height: 1.1;
    border: solid 2px white;
  }

  input::placeholder {
    color: #666666; /* Placeholder text color */
  }

  input:focus{
    border: solid 2px #0057FF;
    outline: none; /* Remove default focus outline */
    border-radius: 0; /* Remove border-radius */
    box-shadow: none; 
  }

  .formButton {
    padding: 6px 24px;
    background-color: white;
    color: black;
    cursor: pointer;
    border: solid 2px white;
    font-size: 14px;
    line-height: 1.1;
  }

  .formButtonActive{
    background: #0057FF;
    border: solid 2px #0057FF;
    color: white;
  }
.article-list-container {
    width: 100%;
    max-width: 740px;
    padding: 20px;
    border-radius: 20px;
    background: white;
    box-sizing: border-box;
  }
  
  .cardContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 20px;
  }

  .cardContainer > *{
    flex: 1;
  }
  
  .mainArticle {
    width: fit-content;
  }
  
  .secondary-articles {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .secondary-article {
    margin-bottom: 20px;
  }
  
  .articleIMG{
    width: 100%;
    border-radius: 10px;
  }

  .smallText{
    font-size: 12px;
    color: #474747;
    margin: 10px 0;
  }

  .trigger{
    padding: 12px;
    border: none;
    font-size: 14px;
    color: white;
    background: #0057FF;
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
    transition: 0.5s
  }

  .trigger:hover{
    background: #3378FF;
  }

  .cardTitle{
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: solid 3px black;
  }

  .black{
    color: black;
  }

  .noMar{
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  @media only screen and (max-width: 768px) {

    .article-list-container {
      max-width: 768px;
      width: 100%;
    }

    .cardContainer > * {
      max-width: 100%;
    }

    .mainArticle{
      margin-bottom: 20px;
    }

    .articleIMG{
      width: 100%;
      max-width: 768px;
      margin-bottom: 10px;
    }

    .cardContainer {
        flex-direction: column;
    }
    
}
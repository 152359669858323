body {
    background: black;
}

.mainContainer{
    min-width: 320px;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 40px;
}

.flexBet{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .heroGap{
        column-gap: 80px;
    }

    .rowGap{
        column-gap: 140px;  
    }

.paddingElement{
    padding: 100px 0;
}

.heroContent{
    min-width: 320px;
    max-width: 520px;
    width: 100%;
}

.heroImg{
    min-width: 320px;
    max-width: 520px;
    width: 100%;
    border-radius: 20px;
}

.greyText{
    color: #666666;
}

.industryLogo{
    height: 35px;
}

.marginFort{
    margin-bottom: 40px;
}

.elementHeader{
    margin: 80px auto;
}

.elementRow{
    max-width: 740px;
    min-width: 320px;
    width: 100%;
    margin: 0 auto;
}

.elementRows{
    display: flex;
    flex-direction: column;
    row-gap: 300px;
}

.mainFooter{
    min-width: 320px;
    max-width: 1140px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 80px auto 0 auto;
    padding: 20px 0;
    border-top: solid 1px #666666;
}

.greyBG{
    background: grey;
}

.testimonialElement{
    padding: 40px;
    border-radius: 40px;
    background-color: #0057FF;
}

.fitContent{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
}

.testimonialImg{
    width: 40px;
    border-radius: 40px;
    margin-right: 10px;
}

.quoteText{
    font-size: 36px;
}

.margT{
    margin-bottom: 20px;
}


@media only screen and (max-width: 960px) {
        
    .mainContainer{
        padding: 20px;
    }
    
    .industryLogo{
        height: 40px;
    }

    .elementHeader{
        margin: 40px auto;
    }

    .elementRows{
        row-gap: 100px;
    }

    .paddingElement{
        padding: 40px 0;
    }

    .flexBet{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        row-gap: 40px;
        width: 100%; /* Add this line */
    }

    .reverse{
        flex-direction: column-reverse;
    }
    
    .fitContent{
        margin-top: 40px;
        flex-direction: column;
        column-gap: 10px;
    }

    .mainFooter{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        padding: 40px 0 20px 0;
        border-top: solid 1px #666666;
        row-gap: 40px;
    }
    
}

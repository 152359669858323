.body {
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    color: black;
  }
  
  .container {
    min-width: 320px;
    max-width: 960px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .navbar {
    width: 100%;
    background-color: white;
    border: 1px solid #E6E6E6;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 100px;
    box-sizing: border-box;
  }
  
  .mainLogo{
    text-decoration: none;
  }
  
  .mainButton {
    padding: 12px 24px;
    background-color: #0057FF;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 600;
    transition: 0.5s;
  }

  .mainButton:hover{
    background-color: #004EE5;
  }
  
  .list {
    width: 100%;
  }
  
  .list-item {
    background-color: white;
    border: 1px solid #E6E6E6;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 20px;
    overflow: hidden;
  }
  
  .list-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .title {
    margin: 0;
  }
  
  .right-side {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  
  .price {
    font-size: 1.2em;
    color: #333;
  }
  
  .buy-button {
    padding: 8px 16px;
    background-color: #0057FF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .image {
    width: 100%;
    max-height: 300px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }
  
.smallContainer{
    max-width: 740px;
    padding: 0 20px;
}

.articleElement{
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px #666666;
}

.articleSmall{
    color: #e6e6e6;
    font-size: 12px;
}

.blankLink{
    text-decoration: none;
    color: white;
}

.articleNav{
    position: sticky;
    top: 0px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: solid 1px #666666;
    background: black;
}

.rowG{
    column-gap: 10px;
}

.hoverLink{
    transition: 0.5s;
}

.hoverLink:hover{
    color: white;
}

.footerFixed{
    position: sticky;
    bottom: 0;
    min-width: 320px;
    max-width: 740px;
    width: 100%;
    background: black;
    padding: 20px 0;
    border-top: solid 1px #666666;
}

.footerFixed p {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.noFlex{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
}

  